<template>
  <section class='section'>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Inventario: {{total}}</h1>
        </div>
      </div>
      <div style="display: flex; flex-wrap: wrap; gap: 10px; padding: 10px 0; justify-content: end;">
        <div class="level-item">
          <button
            class="button"
            type="button"
            :class="{ 'is-loading': isLoading }"
            @click="getCsvDownload"
          >
            Descargar
          </button>
        </div>
        <!-- <div class="level-item">
          <div class="control">
            <input class="input" style="max-width: 150px;" v-model="currentFilters.username" @input="updateFilters({ username: currentFilters.username })" placeholder="Buscar por usuario...">
          </div>
        </div> -->
        <div class="level-item">
          <div class="control">
            <input class="input" style="max-width: 150px;" v-model="search" @input="searchProducts" placeholder="Buscar por producto...">
          </div>
        </div>
        <div class="level-item">
          <div class="select is-fullwidth" @change="filterProducts">
            <select class="select" v-model="currentFilters.filter">
              <option value="0">Todos</option>
              <option value="1">Pendiente</option>
              <option value="2">Activo</option>
              <option value="3">Vendido</option>
              <option value="4">Eliminado</option>
            </select>
          </div>
        </div>
        <div class="level-item">
          <button @click="toggleDatePicker" v-if="!showDatePicker" class="button">
            Filtrar por fecha
          </button>
          <div v-else style="max-width: 150px; overflow: hidden;">
            <v-date-picker
              mode="range"
              v-model="date"
              :max-date="today"
              @input="onDateChange"
            />
          </div>
          <button @click="resetDate" v-if="showDatePicker" class="button" style="padding: 4px;">
            <span class="material-icons">
              restart_alt
            </span>
          </button>
        </div>
        <div class="level-item">
          <router-link
            class="button button-givu"
            :to="{name: 'producto'}"
          >
            Agregar
          </router-link>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          :columns="computedColumnsData"
          :data="productList"
          :loading=loading
          @sort="handleSort"
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="currentFilters.page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import mixin from '../../mixin/validateDate'
const PREOWNED = 2

export default {
  mixins: [mixin],
  name: 'Inventory',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      addUserModal: false,
      modalEditAdmin: false,
      pages: '',
      total: 0,
      search: '',
      loading: false,
      isLoading: false,
      productList: [],
      date: {},
      today: moment().add(1, 'days').format('YYYY-MM-DD'),
      showDatePicker: false,
      columnsData: [
        {
          id: 'productId', header: '#', class: 'column-mobile'
        },
        { id: 'name',
          header: 'Nombre del Producto',
          class: 'column-mobile',
          type: 'link',
          link: true,
          html: true,
          accessor: (row) => `<div style="display:flex; align-items: center; font-weight: ${!row.openedadmin ? '800' : '200'};">
            
            <div
              data-tooltip="${row.statusId ? row.status : ''}"
              class="has-tooltip-top"
              style="width: 8px; height: 8px; border-radius: 50%; margin-right: 5px; background-color: ${row.statusId === 4 ? 'hsl(348, 100%, 61%)' : ''}${row.statusId === 1 ? 'hsl(204, 86%, 53%)' : ''}${row.statusId === 2 ? 'hsl(141, 71%, 48%)' : ''}${row.statusId === 5 ? '#FFC107' : ''}"
            >
            </div>
              
            <div
              data-tooltip="${row.visible ? 'El producto está visible' : 'El producto se ha ocultado'}"
            >
              ${row.visible ? '👁️' : '<span style="text-decoration:line-through;filter: gray;-webkit-filter: grayscale(1);filter: grayscale(1);">👁️</span>'}
              
            </div>
            ${row.name}</div>`,
          route: (row) => `inventario/detalle/${row.productId}`
        },
        {
          id: 'nameuser',
          header: 'Usuario',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.userId}`
        },
        {
          id: 'price',
          header: this.priceHeader,
          class: 'column-mobile',
          sortable: true,
          clickEvent: this.toggleSortOrder,
          accessor: (row) => {
            const priceDisplay = row.price ? `${parseFloat(row.price).toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}` : 'N/A'
            return priceDisplay
          }
        },
        {
          id: 'stock',
          header: this.stockHeader,
          class: 'column-mobile',
          sortable: true,
          clickEvent: this.toggleSortOrder,
          accessor: (row) => row.stock
        },
        {
          id: 'status',
          header: 'Estado',
          class: 'column-mobile',
          accessor: (row) => row.status
        },
        {
          id: 'rejected_message',
          header: 'Motivo de Rechazo',
          class: 'column-mobile has-break-word',
          accessor: (row) => row.rejected_message
        },
        {
          id: 'view_by_administrator',
          header: 'Visto por el admin',
          class: 'column-mobile',
          accessor: (row) => row.adminName
        },
        {
          id: 'condition',
          header: 'Condición',
          class: 'column-desktop',
          accessor: (row) => row.condition === PREOWNED ? 'Seminuevo' : 'Nuevo'
        },
        {
          id: 'createdAt',
          header: 'Fecha y hora de creación',
          class: 'column-mobile',
          accessor: (row) => `${this.formatDateTimestamp(row.createdAt)} ${this.formatDateTimestampHour(row.createdAt)}`
        },
        {
          id: 'updatedAt',
          header: 'Fecha y hora de actualización',
          class: 'column-mobile',
          accessor: (row) => `${this.formatDateTimestamp(row.updatedAt)} ${this.formatDateTimestampHour(row.updatedAt)}`
        }
      ],
      currentFilters: {
        page: 1,
        filter: 0,
        search: '',
        sortKey: 'price',
        sortOrder: 'asc',
        username: '',
        startDate: '',
        endDate: '',
        onlyGiver: true
      }
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (query) {
        this.setFiltersFromQuery(query)
        this.fetchProducts()
      }
    }
  },
  computed: {
    priceHeader () {
      return `Precio ${this.currentFilters.sortKey === 'price' ? (this.currentFilters.sortOrder === 'asc' ? '↑' : '↓') : '↑↓'}`
    },
    computedColumnsData () {
      return this.columnsData.map(column => {
        if (column.id === 'price') {
          return {
            ...column,
            header: `Precio ${this.currentFilters.sortKey === 'price' ? this.sortIcon : ''}`
          }
        }
        if (column.id === 'stock') {
          return {
            ...column,
            header: `Stock ${this.currentFilters.sortKey === 'stock' ? this.sortIcon : ''}`
          }
        }
        return column
      })
    },
    sortIcon () {
      return this.currentFilters.sortOrder === 'asc' ? '↑' : '↓'
    },
    sortedColumnsData () {
      return this.columnsData.map(column => {
        if (column.id === 'price') {
          return {
            ...column,
            header: `Precio ${this.sortKey === 'price' ? this.sortIcon : ''}`,
            clickEvent: () => this.toggleSortOrder('price')
          }
        }
        return column
      })
    }
  },
  methods: {
    ...mapActions(['getProducts', 'csvDownloadProducts', 'addTemporalNotification']),
    paginator (newPage) {
      this.updateFilters({ page: newPage })
    },
    fetchProducts () {
      this.loading = true
      this.getProducts(this.currentFilters)
        .then(resp => {
          this.productList = resp.payload
          console.log(this.productList, 'test')
          this.pages = resp.totalPages
          this.loading = false
          this.total = resp.total
        })
        .catch(error => {
          this.addTemporalNotification({ type: 'danger', message: error.message })
          this.loading = false
        })
    },
    setFiltersFromQuery (query) {
      this.currentFilters.page = parseInt(query.page, 10) || 1
      this.currentFilters.filter = parseInt(query.filter, 10) || 0
      this.currentFilters.search = query.search || ''
      this.currentFilters.sortKey = query.sortKey || 'product.updatedAt'
      this.currentFilters.sortOrder = query.sortOrder || 'desc'
      this.currentFilters.startDate = query.startDate || ''
      this.currentFilters.endDate = query.endDate || ''

      // Llama a fetchProducts para cargar los productos después de establecer los filtros
      this.fetchProducts()
    },
    updateFilters (newFilters) {
      this.currentFilters = { ...this.currentFilters, ...newFilters }
      this.$router.push({ name: 'inventario', query: this.currentFilters }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err)
        }
      })
    },
    updateRouteQuery () {
      const query = { ...this.currentFilters }
      this.$router.push({ name: 'inventario', query }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err)
        }
      })
    },
    handleSort (sortKey) {
      const newSortOrder = this.currentFilters.sortKey === sortKey && this.currentFilters.sortOrder === 'asc' ? 'desc' : 'asc'
      this.updateFilters({ sortKey, sortOrder: newSortOrder })
    },
    toggleSortOrder () {
      const newSortOrder = this.currentFilters.sortOrder === 'asc' ? 'desc' : 'asc'
      this.updateFilters({ sortOrder: newSortOrder })
    },
    sortProductList () {
      this.productList.sort((a, b) => {
        let comparison = 0
        if (a[this.sortKey] < b[this.sortKey]) {
          comparison = -1
        } else if (a[this.sortKey] > b[this.sortKey]) {
          comparison = 1
        }
        return this.sortOrder === 'desc' ? comparison * -1 : comparison
      })
    },
    searchProducts ({ target }) {
      const search = target.value
      if (search.length >= 3 || search.length === 0) {
        this.updateFilters({ search, page: 1 })
      }
    },
    filterProducts ({ target }) {
      const filter = parseInt(target.value, 10) || 0
      this.updateFilters({ filter, page: 1 })
    },
    getCsvDownload () {
      this.isLoading = true
      const { filter, search, startDate, endDate } = this.$router.history.current.query
      this.currentFilters.filter = filter === '' || filter === undefined ? this.currentFilters.filter : parseInt(filter, 10)
      this.currentFilters.search = search === '' ? this.currentFilters.search : search
      this.currentFilters.startDate = startDate === '' ? this.currentFilters.startDate : startDate
      this.currentFilters.endDate = endDate === '' ? this.currentFilters.endDate : endDate

      this.csvDownloadProducts({ filter: this.currentFilters.filter, search: this.currentFilters.search, startDate: this.currentFilters.startDate, endDate: this.currentFilters.endDate, onlyGiver: this.currentFilters.onlyGiver })
        .then(resp => {
          if (resp) {
            const exportName = 'Export products.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    },
    onDateChange (newDate) {
      if (newDate == null) return null

      this.currentFilters.startDate = moment(newDate?.start).format('YYYY-MM-DD')
      this.currentFilters.endDate = moment(newDate?.end).format('YYYY-MM-DD')

      this.updateFilters({ page: 1 })
    },
    toggleDatePicker () {
      this.showDatePicker = !this.showDatePicker
    },
    resetDate () {
      this.date = {}
      this.showDatePicker = false
      this.currentFilters.startDate = ''
      this.currentFilters.endDate = ''

      this.updateFilters({ page: 1 })
    }
  },
  beforeMount () {
    this.setFiltersFromQuery(this.$route.query)
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
</style>

<style>
  .has-break-word {
    word-break: break-word;
  }
  .level-right {
    width: fit-content;
    flex-flow: wrap;
    row-gap: 10px;
  }
</style>
